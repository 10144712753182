<template>
  <footer>
    <div class="container">
      <div class="row">

        <div class="footer-logo col-lg-4">
          <img src="images/logo-nav.svg" alt="" class="img-fluid" />
          <p>興城國際有限公司版權所有轉載必究@2022</p>
        </div>

        <div class="footer-link col-lg-4">
          <ul>
            <li>
              <router-link :to="{name:'invite'}">邀約合作</router-link>
            </li>
            <li>
              <router-link :to="{name:'contact'}">客戶售服</router-link>
            </li>
          </ul>

          <ul>
            <li>
              <router-link :to="{name:'land-service'}">土地評估</router-link>
            </li>
            <li>
              <router-link :to="{name:'privacy'}">隱私權政策</router-link>
            </li>
          </ul>
        </div>

        <div class="footer-social col-lg-4">
          <ul>
            <li>
              <a href="https://www.facebook.com/%E8%88%88%E5%9F%8E%E5%9C%8B%E9%9A%9B-103463618524904"
                  target="_blank">
                  <img src="images/connect-icon1.svg" alt="" target="_blank">
              </a>
          </li>

          <li>
              <a href="https://line.me/R/ti/p/@576jxkha" target="_blank">
                  <img src="images/connect-icon6.svg" alt="" target="_blank">
              </a>
          </li>

          <li>
              <a href="https://g.page/xincity?share" target="_blank">
                  <img src="images/connect-icon2.svg" alt="" target="_blank">
              </a>
          </li>

          <li>
              <a href="tel:(07)3228999">
                  <img src="images/connect-icon5.svg" alt="">
              </a>
          </li>
          </ul>
        </div>

        <div class="footer-contact col-lg-12">
          <p>807高雄市三民區博愛一路366號10樓之一</p>
          <p>TEL｜07-322-8999 &nbsp;FAX｜07-322-8555</p>
          <p>興城國際有限公司版權所有轉載必究@2021</p>
        </div>
        
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponents",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer-link{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer .footer-link ul{
  margin: 45px 0 0 0;
}

@media screen and (max-width:992px) {
  footer .footer-link ul{
    margin: 0px 0 5px 0;
  }
  
}
.footer .footer-link ul:nth-child(2){
  margin-left: 0px
}

</style>
